import React from "react";
import moment from "moment";

//components
import { Col, Row, Stack } from "react-bootstrap";
import IconButton from "../buttons/_IconButton";
import LargeButton from "../buttons/_LargeButton";

//hooks
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { setSelectedDay } from "../../../../redux/features/profile/profileSlice";
import { useGetUsers } from "../../../../hooks/useGetUsers";
import { useNavigate } from "react-router-dom";

//services
import * as dateHelper from "../../../../services/utils/utilities";
import { getToday } from "../../../../services/utils/dateUtils";
import { INTERNAL_ROUTES } from "../../../../routing/nav_routes";
import { getActingAsUser } from "../../../../services/utils/userUtils";

const HeaderWithDaySelection = ({ schedulesCount }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { selectedDay } = useSelector((state) => state.profile);
  const { currentUser, users, isLoading: usersLoading } = useGetUsers();
  const forDate = moment(selectedDay.date, "M-D-YYYY").toDate();
  const monthName = dateHelper.getMonthName(forDate);
  const dayName = dateHelper.getDayName(forDate);
  const dayOfMonth = forDate.getDate();


  const getActingAsUserName = () => {
    var actingUser = getActingAsUser(currentUser, users);
    return actingUser?.fullName ?? "N/A";
  };

  const onSwitchInspectorClick = () => {
    navigate(INTERNAL_ROUTES.SWITCH_INSPECTOR);
  };

  const onNavigateBack = () => {
    let newSelectedDay = {
      date: moment(selectedDay.date, "M-D-YYYY")
        .subtract(1, "days")
        .format("M-D-YYYY"),
      dayOfWeek: moment(selectedDay.date, "M-D-YYYY")
        .subtract(1, "days")
        .format("dddd"),
    };

    updateSelectedDay(newSelectedDay);
  };

  const onNavigateForward = () => {
    let newSelectedDay = {
      date: moment(selectedDay.date, "M-D-YYYY")
        .add(1, "days")
        .format("M-D-YYYY"),
      dayOfWeek: moment(selectedDay.date, "M-D-YYYY")
        .add(1, "days")
        .format("dddd"),
    };
    
    updateSelectedDay(newSelectedDay);
  };

  const updateSelectedDay = (paload) => {
    dispatch(setSelectedDay(paload));
  };

  const onBackClick = () => {
    onNavigateBack();
  };

  const onForwardClick = () => {
    onNavigateForward();
  };

  const onToToday = () => {
    updateSelectedDay(getToday());
  };

  const today = getToday();
  const todayDisabled = selectedDay.date === today.date;
  const backDisabled =
    moment(new Date(selectedDay.date))
      .subtract(1, "days")
      .diff(moment(new Date(today.date)), "days") <= -22;
  const forwardDisabled =
    moment(new Date(selectedDay.date))
      .add(1, "days")
      .diff(moment(new Date(today.date)), "days") >= 11;

  return (
    <div className="px-2 mb-1">
      <Row className="align-items-center justify-content-between">
        <Col className="col-auto me-auto">
          <Stack direction="horizontal">
            <IconButton
              title="Back"
              onClick={onBackClick}
              iconClass={"ri-arrow-left-s-line"}
              size={"lg"}
              disabled={backDisabled}
            />
          </Stack>
        </Col>
        <Col>
          <div className="d-flex justify-content-center">
            <LargeButton
              size={"md"}
              title={"Back to Today"}
              onClick={onToToday}
              fontSize={"fs-4"}
              disabled={todayDisabled}
            />
          </div>
        </Col>
        <Col>
          <Stack
            direction="horizontal"
            gap={1}
            className="justify-content-center p-2"
          >
            <Stack
              direction="vertical"
              gap={1}
              className="justify-content-center p-2 flex-fill"
            >
              <h3 className="m-0">
                {dayName}, {monthName} {dayOfMonth}
              </h3>
              <h3 className="m-0">{schedulesCount} total</h3>
            </Stack>
          </Stack>
        </Col>
        <Col>
          <Stack
            direction="vertical"
            gap={2}
            className="justify-content-center"
          >
            <h4>{`Acting as ${getActingAsUserName()}`}</h4>
            <div className="d-flex justify-content-center">
              <LargeButton
                size={"sm"}
                title={"Switch Inspector"}
                fontSize={"fs-4"}
                onClick={onSwitchInspectorClick}
                loading={usersLoading}
                disabled={usersLoading}
              />
            </div>
          </Stack>
        </Col>
        <Col className="col-auto">
          <Stack direction="horizontal">
            <IconButton
              title="Forward"
              onClick={onForwardClick}
              iconClass={"ri-arrow-right-s-line"}
              size={"lg"}
              disabled={forwardDisabled}
            />
          </Stack>
        </Col>
      </Row>
      <Row></Row>
    </div>
  );
};

export default HeaderWithDaySelection;
